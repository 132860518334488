import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { FuseConfigService } from "@fuse/services/config.service";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import moment from "moment";
import { TransferDialogComponent } from "../sied/panel-control/transfer-dialog/transfer-dialog.component";
import { FormacionesDialog } from "./formaciones-dialog/formaciones-dialog.component";

@Component({
	selector: "app-vuelta-d",
	templateUrl: "./vuelta-d.component.html",
	styleUrls: ["./vuelta-d.component.scss"],
	animations: [feedAnimations],
})
export class VueltaDComponent implements OnInit {
	user: User;
	evaluationPeriod: any;
	evaluation: any;
	allUserEvaluations: any;

	allCollaborators: any;
	collaborators: any;

	listaPuestos: Array<String>;

	canDo = true;

	projectId;

	projectConfig;
	constructor(
		private _fuseConfigService: FuseConfigService,
		public dialog: MatDialog,
		private router: Router,
		private projectService: ProjectService,
		private userSvc: UserService,
		private personSvc: PersonService,
		private imageSvc: ImagesService,
		private evaluationSvc: EvaluationService,
		private projectSvc: ProjectService,
	) {
		this.evaluationPeriod = { name: "" };
		this.listaPuestos = [];
		this.collaborators = [];
		this._fuseConfigService.config = {
			layout: {
				navbar: {
					secondaryBackground: "feedbalia-navbar2-500",
				},
				toolbar: {
					background: "feedbalia-navbar2-500",
				},
			},
		};

		this.projectConfig = this.projectSvc.projectConfig;
	}

	ngOnInit(): void {
		// Check page access
		this.projectId = parseInt(this.projectSvc.getProjectId());

		this.projectService.getProjectConfig(false).subscribe((project) => {
			if (!project.enabled_mod_vh) {
				this.router.navigate(["/inicio"]);
			}
		});

		this.userSvc.getUser().subscribe((data: User) => {
			this.user = data;
			if (!this.projectConfig) {
				this.projectConfig = this.projectSvc.projectConfig;
			}
			if (this.projectConfig.tandemMode == 1) {
				this.canDo = false;

				this.evaluationSvc.getRegistroTandem(this.user.id).subscribe((data) => {
					this.canDo = data.canDo;
				});
			}
			this.evaluationSvc.getEvaluationPeriods("EVALUATION_VH").subscribe((data: any) => {
				this.evaluationPeriod = data[0];
				this.evaluationSvc.getEvaluationVHByEvaluator(this.evaluationPeriod.id, this.user.id).subscribe((data: any) => {
					this.allCollaborators = data.filter((e) => e.personId !== this.user.id);
					this.collaborators = this.allCollaborators;
					this.collaborators.forEach((e) => {
						// Cargar puestos para filtro
						if (!this.listaPuestos.includes(e.puesto)) {
							this.listaPuestos.push(e.puesto);
						}
						// Cargar todas las vueltas anteriores
						this.loadAllEvaluations(e.personId);
						// Transferencias
						if (e.type === "TRANSFER") {
							this.personSvc.getPerson(e.originalEvaluator).subscribe((data) => {
								e.originalEvaluator = data.displayName;
							});
						} else {
							this.evaluationSvc.isEvaluationTransfered(e.personId, this.evaluationPeriod.id).subscribe((data) => {
								if (data) {
									e.transferedTo = data;
								}
							});
						}
					});
					console.log("getEvaluationVHByEvaluator", this.collaborators);
				});
				this.evaluationSvc.getEvaluationVHByUser(this.evaluationPeriod.id, this.user.id, this.user.puestos[0].id).subscribe((data: any) => {
					this.evaluation = data;
					console.log("getEvaluationVHByUser", this.evaluation);
				});
				//Cargar las vueltas anteriores del usuario
				this.evaluationSvc.findAllEvaluationVHHistoryByUser(this.user.id).subscribe((data: any) => {
					this.allUserEvaluations = data;
				});
			});
		});
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	formatDate(date: string) {
		if (!date) {
			return "";
		}
		let _date = moment(date).format("DD/MM/YYYY HH:mm");
		return _date;
	}

	getCalendarLink(evaluator) {
		if (!evaluator) {
			return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Propuesta%20reunión%20Vuelta%20D%20N%C2%BA${
				this.evaluation.iterationNumber ? Number.parseInt(this.evaluation.iterationNumber) + 1 : "1"
			}&sprop=https%3A%2F%2Fwww.sermas365.feedbalia.com&add=${encodeURI(
				this.user.email,
			)}&details=Reunión%20para%20realizar%20la%20vuelta%20D%20N%C2%BA${
				this.evaluation.iterationNumber ? Number.parseInt(this.evaluation.iterationNumber) + 1 : "1"
			},%20para%20el%20puesto%20${this.user.puestos[0].puesto}%20del%20colaborador%20${this.user.displayName}`;
		} else {
			return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Propuesta%20reunión%20Vuelta%20D%20N%C2%BA${
				evaluator.iterationNumber ? Number.parseInt(evaluator.iterationNumber) + 1 : "1"
			}&sprop=https%3A%2F%2Fwww.sermas365.feedbalia.com&add=${encodeURI(
				evaluator.email,
			)}&details=Reunión%20para%20realizar%20la%20vuelta%20D%20N%C2%BA${
				evaluator.iterationNumber ? Number.parseInt(evaluator.iterationNumber) + 1 : "1"
			},%20para%20el%20puesto%20${evaluator.puesto}%20del%20colaborador%20${evaluator.displayName}`;
		}
	}

	getStarsIndex(avg) {
		let _index = Math.floor(avg / 16.66);
		let _array = new Array(5);
		for (let i = 0; i < 5; i++) {
			if (i < _index) {
				_array[i] = "X";
			} else {
				_array[i] = "-";
			}
		}
		return _array;
	}

	onChangeFilter(value) {
		if (value === "null") {
			this.collaborators = this.allCollaborators;
			return;
		}
		this.collaborators = this.allCollaborators.filter((e) => e.puesto === value);
	}

	onChangeSort(value) {
		if (value === "iterationNumber") {
			this.collaborators.sort((a, b) => {
				if (a[value] > b[value]) {
					return 1;
				}
				if (a[value] < b[value]) {
					return -1;
				}
				return 0;
			});
		} else {
			this.collaborators.sort((a, b) => {
				if (a[value] < b[value]) {
					return 1;
				}
				if (a[value] > b[value]) {
					return -1;
				}
				return 0;
			});
		}
	}

	getBlockByUser(personId) {
		let currentEvaluator = this.collaborators.find((e) => e.personId === personId);
		if (currentEvaluator.isBlockHidden === "1") {
			currentEvaluator.isBlockHidden = "0";
			return;
		}
		this.evaluationSvc.getBlockByEvaluationPerson(personId).subscribe((data: any) => {
			currentEvaluator.isBlockHidden = "1";
			currentEvaluator.block = data;
		});
	}

	loadAllEvaluations(personId) {
		let currentEvaluator = this.collaborators.find((e) => e.personId === personId);
		this.evaluationSvc.findAllEvaluationVHHistoryByUser(personId).subscribe((data: any) => {
			currentEvaluator.allEvaluations = data;
		});
	}

	loadEvaluation(evaluation) {
		const _params = {
			formId: evaluation.evaluationFormId,
			personId: evaluation.personId,
			periodId: evaluation.periodId,
			iterationNumber: evaluation.iterationNumber,
		};
		this.router.navigate(["/vuelta-d/form"], { queryParams: _params });
	}

	loadAutoEvaluation(evaluation, isNew?: boolean) {
		const _params = {
			formId: evaluation.formId,
			personId: this.user.id,
			periodId: this.evaluationPeriod.id,
			iterationNumber: isNew ? Number(evaluation.iterationNumber) + 1 : evaluation.iterationNumber,
		};
		this.router.navigate(["/vuelta-d/form"], { queryParams: _params });
	}

	loadCollaboratorEvaluation(collaborator, isNew?: boolean) {
		const _params = {
			formId: collaborator.formId,
			personId: collaborator.personId,
			periodId: this.evaluationPeriod.id,
			iterationNumber: isNew ? Number(collaborator.iterationNumber) + 1 : collaborator.iterationNumber,
		};
		this.router.navigate(["/vuelta-d/form"], { queryParams: _params });
	}

	openJobSection(collaborator) {
		const _params = {
			personId: collaborator.personId,
			puesto: collaborator.puesto,
		};
		this.router.navigate(["vuelta-d/job-section"], { queryParams: _params });
	}

	openTransferDialog(user) {
		const dialogRef = this.dialog.open(TransferDialogComponent, {
			data: {
				transferedTo: user.transferedTo,
				personId: user.personId,
				periodId: this.evaluationPeriod.id,
				close: (_callback) => {
					user.transferedTo = _callback;
					dialogRef.close();
				},
			},
			panelClass: "panel-vuelta-d",
			autoFocus: false,
			maxWidth: "50vw",
			minWidth: "40vw",
		});
	}

	openFormaciones(user) {
		const dialogRef = this.dialog.open(FormacionesDialog, {
			data: {
				personID: user.personId,
				puesto: user.puesto?.puesto,
				close: (_callback) => {
					dialogRef.close();
				},
			},
			panelClass: "panel-vuelta-d",
			autoFocus: false,
			maxWidth: "50vw",
			minWidth: "40vw",
		});
	}

	getFecha(element) {
		if (element) {
			return moment(element).format("DD-MM-YYYY");
		} else {
			return "";
		}
	}
}
